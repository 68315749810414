@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.container {
  height: 100%;
  position: relative;
  .inner {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    .imgWrapper {
      max-width: 500px;
      min-width: 250px; 
      min-height: 200px; 
      margin: 0 auto;
    }
    svg {
      max-width: 400px;
    }
  }
  .form {
    .termsOfUseConsent div div {
      justify-content: center;
    }
    .btnWrapper {
      text-align: center;
      button {
        min-width: 200px;
      }
    }
  }
}

.imgContainer {
  padding-bottom: 70% !important;
  max-height: 350px;

  @include breakpoint-up(md) {
    padding-bottom: 50% !important;
  }
}
.img {
  width: 100% !important;
  @include breakpoint-up(md) {
    width: 70% !important;
  }
}
