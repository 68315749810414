@import 'styles/variables.scss';

.switchWrapper {
  display: flex;
  align-items: center;
  top: 8px;
  margin-bottom: 20px !important;
  padding-top: 0 !important;
}
.switchLabel {
  color: $dark-gray-color;
  font-weight: normal;
  line-height: 19px;
  position: static !important;
  height: auto !important;
  order: 1;
}
.switch {
  margin-right: 8px;
  div {
    border: 1px solid $medium-gray-color;
  }
}

.switchOn div {
  border: 1px solid $primary-color;
}
