@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.resultTable {
  td {
    padding: 5px !important;
    @include breakpoint-up(md) {
      padding: 10px !important;
    }
  }
}

.showAllCalculations {
  cursor: pointer;
  color: $primary-color;
  margin-top: 10px;
  @include breakpoint-up(xl) {
    display: inline-block;
    margin-top: 0;
    margin-left: 10px;
  }
}

.pageNameCol {
  margin: 20px 0;
  h1 {
    text-align: left;
  }
  @include breakpoint-up(md) {
    margin: 0;
  }
}

.buttonsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  button:first-child {
    margin-bottom: 5px;
  }
  @include breakpoint-up(md) {
    flex-wrap: nowrap;
    button:first-child {
      margin-right: 5px;
      margin-bottom: 0;
    }
  }
}

.inputControllsWrapperPencil {
  path {
    transition: fill 0.4s ease;
  }
  &:hover path {
    fill: $svg-pencil-transition-color;
  }
}
.inputControllsWrapperCross {
  path {
    transition: fill 0.4s ease;
  }
  &:hover path {
    fill: $svg-cross-transition-color;
  }
}
.inputControllsWrapperTick {
  path {
    transition: fill 0.4s ease;
  }
  &:hover path {
    fill: $svg-tick-transition-color;
  }
}

.btnsWrapper {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column-reverse;

  @include breakpoint-up(md) {
    flex-direction: row;
  }
  .btnRenovateHouse {
    min-width: 250px;
    width: 100%;
    margin-bottom: 16px;
    svg,
    path {
      stroke: white;
      fill: white;
    }
    @include breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
}

.actionBar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & :not(:last-child) {
    margin-right: 5px;
  }
}
