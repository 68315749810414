body,
html {
  height: 100%;
  margin: 0;
  font-family: $font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
}

.subtitle {
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  @include breakpoint-up(lg) {
    text-align: left;
  }
}

.subtitle-description {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 16px;
  text-align: center;
  @include breakpoint-up(lg) {
    text-align: left;
  }
}

.text-secondary {
  color: #8B8B8B;
}

// input styles
.field-wrapper {
  position: relative;
  padding-bottom: 25px;
  padding-top: 25px;
  // margin-bottom: 10px;
  width: 100%;
  & > label {
    position: absolute;
    display: flex;
    align-items: flex-end;
    color: $font-color;
    height: 32px;
    font-size: 18px;
    left: 0px;
    top: -12px;
    font-weight: 600;
    pointer-events: none;
  }
}

input {
  border: none;
  width: 100%;
  padding: 14px 16px;
  outline: none;
  background: white;
  font-family: $font-family;
  box-shadow: none;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  box-sizing: border-box;
}

.link {
  font-weight: 400;
  color: $primary-color;
  background-color: transparent;
  padding: 0.375em 0;
  font-size: 1em;
  text-decoration: none;
  border: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
}

.muted {
  color: $dark-gray-color;
  font-weight: 400;
}

.default-input {
  border: 1px solid $font-color;
  border-radius: 8px;
}

#root {
  position: relative;
  font-family: $font-family;
  min-height: 100%;
  &,
  * {
    box-sizing: border-box;
  }
}

// .rc-tooltip styles
.rc-tooltip {
  max-width: 300px;
  background-color: transparent;
  opacity: 1;
}

.rc-tooltip-inner {
  font-size: 14px;
  border-radius: 10px;
  color: #242020;
  background-color: white;
}

.rc-tooltip-arrow {
  border-top-color: $primary-color !important;
}

.card {
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  @include breakpoint-up(xl) {
    padding: 36px;
  }
}

.giant-heading {
  font-size: 24px;
  @include breakpoint-up(lg) {
    font-size: 48px;
  }
}

.section-heating {
  font-size: 24px;
}

.paragraph {
  font-size: 20px;
  line-height: 32px;
}
