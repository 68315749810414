@import 'styles/variables';
@import 'styles/breakpoints.scss';

.customFieldWrapper {
  padding-top: 48px;
  label {
    top: 12px;
  }
  @include breakpoint-up(md) {
    padding-top: 24px;
    label {
      top: -12px;
    }
  }
}

.suggestionItemsContainer {
  border-radius: 8px;
  margin-top: 4px;
  &.isActive {
    border: 1px solid $light-gray-color;
  }
}

.suggestionItem {
  padding: 10px 5px;
  padding: 10px;
  color: black;
  cursor: pointer;
}

.suggestionItemActive {
  padding: 10px 5px;
  background-color: $primary-light-color;
  padding: 10px;
  cursor: pointer;
  &:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  &:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}