@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.imageContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-bottom: 100%;
  .img {
    position: absolute;
    inset: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    object-fit: cover;
    width: 70%;
    @include breakpoint-up(md) {
      right: 0;
    }
  }
  .loading {
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
  }
}