.table {
  border-collapse: collapse;
  width: 100%;
  td,
  th {
    padding: 10px 15px;
  }

  thead tr,
  tfoot tr {
    background-color: #f2f1f1;
    border-radius: 6px;
    font-weight: bold;
  }

  thead tr,
  tfoot tr {
    th,
    td {
      border-spacing: unset;
      color: $text-color;
      text-align: left;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0.4em 0;
    }
  }

  &.primary {
    thead tr {
      background-color: $secondary-color;
      th {
        color: $text-inverted-color;
      }
    }
  }

  thead {
    tr {
      background-color: white;
      th {
        padding: 20px 16px;
        text-align: right;
        &:first-of-type {
          text-align: left;
          border-top-left-radius: 16px;
        }
        &:last-of-type {
          border-top-right-radius: 16px;
        }
      }
    }
  }

  &.grey-first {
    thead tr {
      background-color: #F9F9F9;
    }
    tbody tr {
      background-color: white;
      &:nth-child(even) {
        background-color: #F9F9F9;
      }
    }
  }

  tbody {
    tr {
      background-color: #F9F9F9;
      &:nth-child(even) {
        background-color: white;
      }
      td {
        font-weight: 300;
      }
    }
  }

  tfoot tr {
    td {
      padding: 20px 16px;
      font-weight: bold;
      background-color: $secondary-color;
      color: white;
      &:first-of-type {
        border-bottom-left-radius: 16px;
      }
      &:last-of-type {
        border-bottom-right-radius: 16px;
      }
    }
  }

  &.before-last-col-text-right {
    td:nth-last-child(2) {
      // white-space: nowrap;
      text-align: right;
    }
  }

  &.last-col-text-right {
    td:last-child {
      // white-space: nowrap;
      text-align: right;
    }
  }
}
