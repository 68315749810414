@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.potentialValues {
  width: 100%;
  .item {
    border: 1px solid #dbe0e1;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 6px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    @include breakpoint-up(lg) {
      padding: 16px 10px;
    }
    @include breakpoint-up(xl) {
      padding: 16px;
    }
    .info {
      width: 100%;
      .topItem {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 8px;
        gap: 12px;
        font-size: 18px;
        line-height: 24px;
        .label {
          flex: 1;
          margin-top: 0;
          margin-bottom: 0;
          font-weight: bold;
        }
        .wrapper {
          flex: 2;
          display: flex;
          align-items: center;
          gap: 8px;
          width: 100%;
          .barChart {
            flex: 1;
            border-radius: 10px;
            height: 12px;
            position: relative;
            .potentialValue {
              height: 100%;
              position: absolute;
              left: 0;
              top: 0;
              border-radius: 10px;
            }
          }
          .percentage {
            font-weight: bold;
            text-align: end;
          }
        }
        @include breakpoint-up(md) {
          flex-direction: row;
        }
      }

      .bottomItem {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        line-height: 24px;
        width: 100%;
        gap: 8px;
        font-size: 16px;
        .actual {
          flex: 1.5;
          text-align: start;
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
        .potential {
          flex: 2;
          width: 100%;
          display: flex;
          justify-content: space-between;
          text-align: center;
          border-top: 1px solid #242020;
          border-bottom: 1px solid #242020;
          padding: 4px 0;
        }
        .diff {
          flex: 1;
          text-align: end;
          width: 100%;
        }
        @include breakpoint-up(md) {
          flex-direction: row;
          font-size: 16px;
          .actual {
            display: block;
            width: initial;
          }
          .diff {
            display: block;
            width: initial;
          }
          .potential {
            display: block;
            width: initial;
            padding: 0 4px;
            border: none;
            border-left: 1px solid #242020;
            border-right: 1px solid #242020;
          }
        }
        @include breakpoint-up(lg) {
          font-size: 14px;
        }
        @include breakpoint-up(xl) {
          font-size: 16px;
        }
      }
    }
    .circle {
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 36px;
      @include breakpoint-up(xl) {
        max-width: 43px;
        margin-right: 16px;
      }

      path {
        fill: $primary-color !important;
      }
    }
  }
}
