@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.headerWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.energyBalanceCol {
  margin-top: 24px;
  @include breakpoint-up(lg) {
    margin-top: 0;
  }
}
