@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.imagesWrapper {
  position: relative;
  text-align: center;
  .bg {
    border-radius: 24px;
    width: 100%;
  }
  .logo {
    position: absolute;
    bottom: 10%;
    left: 0;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  @include breakpoint-up(md) {
    .logo {
      bottom: 15%;
    }
  }
}

.row {
  padding: 110px 0 60px;
  text-align: center;
  @include breakpoint-up(md) {
    text-align: left;
  }
}

.downloadPdfButton {
  margin-top: 40px;
}
