@import 'styles/variables.scss';

.circleButton {
  border-width: initial;
  border-style: none;
  border-image: initial;
  position: relative;
  outline: none;
  padding: 0;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  font-size: 20px;
  color: $primary-color;
  border: none;
  border-radius: 4px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.default {
  background-color: white;
  &:hover {
    background-color: $primary-light-color;
  }
}

.secondary {
  background-color: $primary-light-color;
  &:hover {
    background-color: darken($primary-light-color, 5%);
  }
}

.outline {
  border: 1px solid $primary-color;
  background-color: white;
  path {
    fill: $primary-color;
  }
  &:hover {
    background-color: $primary-light-color;
  }
}

.primary {
  background-color: $primary-color;
  &:hover {
    background-color: darken($primary-color, 5%);
  }
}
