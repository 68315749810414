@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.content {
  font-family: $font-family;
  & > * {
    line-height: 24px;
  }
  h1 {
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 1rem;
    @include breakpoint-up(md) {
      font-size: 2rem;
      line-height: 36px;
      margin-bottom: 1.5rem;
    }
  }
}
.backButton {
  position: sticky;
  top: 130px;
  left: -20px;
  cursor: pointer;
}
