@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $primary-color 10%, transparent 10%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }
  &:active::after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }
}

$toggleSidebarBtnSize: 40px;
.btnScroll {
  @extend .ripple;
  position: sticky;
  bottom: 50%;
  transform: translateY(-50%);
  opacity: 0.95;
  z-index: 1;
  width: $toggleSidebarBtnSize;
  height: $toggleSidebarBtnSize;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 4px 6px 24px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out;
  background-color: #fff;
}

.btnScrollRight {
  @extend .btnScroll;
  left: 78vw;
}

.btnScrollLeft {
  @extend .btnScroll;
  left: 0;
}
