@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.projectTitle {
  text-align: center;
  margin: 48px 0;
}

.whiteBg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  width: 100vw;
  z-index: -1;
  background-color: white;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 16px;
  flex-direction: column;
  h2 {
    text-align: center;
  }
  button {
    margin: auto;
  }
  @include breakpoint-up(lg) {
    flex-direction: row;
    margin-bottom: 24px;
    gap: 8px;
    h2 {
      margin: 0;
    }
    button {
      margin: 0;
    }
  }
}
