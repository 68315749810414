@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.ourPartner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  @include breakpoint-up(md) {
    justify-content: end;
  }
}

.submitButton {
  width: 100%;
  margin-top: 16px;
  @include breakpoint-up(md) {
    margin-top: 0;
  }
}

.confirmationText {
  margin-top: 0;
  margin-bottom: 25px;
}
