@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.fakeInputClass {
  padding: 12px 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}

.fakeInput {
  @extend .fakeInputClass;
  background-color: white;
  border: 1px solid black;
}

.primaryFakeInput {
  @extend .fakeInputClass;
  background-color: $primary-color;
  border: 1px solid $primary-color;
  color: white;
}

.secondaryFakeInput {
  @extend .fakeInputClass;
  background-color: $secondary-color;
  border: 1px solid $secondary-color;
  color: white;
}

.fakeInputWrapper {
  padding: 25px 0 16px;
  position: relative;
  .label {
    position: absolute;
    top: 0;
  }
  flex: 1;
}
