@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.withoutGrid {
  thead tr th,
  tbody tr td {
    border: none;
  }
  th:empty {
    display: none;
  }
  .actualEnergyClass {
    height: 40px;
    width: 75%;
  }
}

.energyScaleTable {
  width: 100%;
  tr td,
  tr th {
    border: none;
  }
  thead tr th {
    background-color: white;
    font-size: 14px;
    padding: 0;
  }
  tbody tr td {
    border-collapse: collapse;
    color: white;
    padding: 0;
    background-color: transparent !important;
    .energyClass {
      padding: 1.5px 5px;
      font-size: 22px;
      font-weight: 600;
      position: relative;
      background-color: #DBE0E1;
      margin-bottom: 2px;
      .triangle {
        position: absolute;
        display: inline-block;
        right: -17.5px;
        top: 0;
        width: 0;
        border-left: 18px solid #DBE0E1;
        border-top: 18px solid transparent !important;
        border-bottom: 18px solid transparent !important;
        border-right: none;
      }
    }
  }
}

.houseEnergyIndicators {
  border: 1px solid $light-gray-color;
  padding: 28px 16px;
  border-radius: 6px;
  text-align: center;
  .label {
    text-transform: uppercase;
    font-weight: 500;
    margin-top: 0;
    font-size: 16px;
  }
  .heatingDemandValue {
    display: block;
    font-size: 24px;
    margin: 16px 0;
  }
  .btnWrapper {
    margin-top: 40px;
  }
  margin-top: 16px;
  @include breakpoint-up(md) {
    .heatingDemandValue {
      margin: 36px 0;
    }
  }
  @include breakpoint-up(lg) {
    text-align: center;
    margin-top: 0;
    .label {
      font-size: 18px;
    }
  }
  @include breakpoint-up(xl) {
    .label {
      font-size: 20px;
    }
  }
}

.actualEnergyClass {
  .label {
    color: black;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    min-width: 110px;
    padding-left: 8px;
  }
}
