@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.card {
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  .chartWrapper {
    // max-width: 800px;
    // height: 650px;
  }
  @include breakpoint-up(md) {
    height: 100%;
    .chartWrapper {
      max-width: 800px;
      height: 650px;
    }
  }
}