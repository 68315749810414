@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.section {
  margin: 60px 0;
  .title {
    font-size: 24px;
  }
  .details {
    color: #8B8B8B;
    font-size: 18px;
    line-height: 24px;
  }
  .firstCol {
    margin-bottom: 16px;
    @include breakpoint-up(lg) {
      margin-bottom: 0;
      align-self: stretch;
    }
  }
  .secondCol {
    @include breakpoint-up(lg) {
      align-self: stretch;
    }
  }
}