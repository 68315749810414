@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.legend {
  margin-top: 16px;
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
  text-align: left;
  .legendItem {
    display: flex;
    align-items: center;
    gap: 8px;
    .square {
      width: 20px;
      height: 20px;
    }
    .label {
      font-size: 12px;
      line-height: 14px;
    }
  }
  @include breakpoint-up(md) {
    flex-direction: row;
    align-items: center;
    gap: 24px;
  }
}