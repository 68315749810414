@import 'styles/variables.scss';

.ripple:not(:disabled) {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $primary-color 10%, transparent 10%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }
  &:active::after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }
}

.counter {
  width: 150px;
  height: 48px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid $light-gray-color;
  border-radius: 6px;
  .value {
    font-size: 16px;
    line-height: 20px;
    color: $gray-color;
    min-width: 20px;
    text-align: center;
  }
  .counterBtn {
    min-width: 30%;
    outline: none;
    border: none;
    background-color: white;
    cursor: pointer;
    padding: 7px 10px;
    display: flex;
    border-radius: 6px;
    svg path {
      fill: $primary-color;
    }
  }
  :disabled {
    svg path {
      fill: lighten($primary-color, 30%);
    }
  }
}
.description {
  text-align: left;
  color: $medium-gray-color;
  font-size: 13px;
  line-height: 18px;
  margin-top: 7px;
}
