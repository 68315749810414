@import 'styles/variables.scss';

.alert {
  border-radius: 0.5rem;
  padding: 24px;
  margin-bottom: 16px;
  min-height: 50px;
  position: relative;
  text-align: left;
}

.danger {
  background-color: #f8d7da;
  border-color: #f5c6cb;
  color: #721c24;
}

.primary {
  background-color: #d3e6f8;
  border-color: #bddaf5;
  color: #0d376f;
}

.warning {
  background: lighten($yellow-color, 35%);
  white-space: pre-line;
  color: $dark-gray-color;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  path {
    fill: $primary-color !important;
  }
}

.iconContainer {
  position: absolute;
  top: 8px;
  right: 8px;
  svg {
    width: 35px;
    height: 35px;
  }
}
