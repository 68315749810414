@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.section {
  background-color: white;
  border-radius: 16px;
  padding: 24px;
  height: 100%;
  label {
    font-size: 16px !important;
    font-weight: normal !important;
  }
  :global(.field-wrapper) {
    padding-bottom: 16px;
    input {
      padding: 12px 10px;
      border: none;
      background-color: #F4F4F4;
    }
  }
}