@import 'styles/variables.scss';

.tabs {
  z-index: 2 !important;
  & ~ div {
    // overflow-x: hidden;
    padding-left: 2px;
    padding-right: 2px;
  }
}
