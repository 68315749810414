@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.fieldWrapper {
  padding-top: 40px;
  label {
    top: 5px;
  }
  @include breakpoint-up(md) {
    padding-top: 25px;
    label {
      top: -12px;
    }
  }
  .radioGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;

    .radioItem {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 10px 0;
      &:last-of-type {
        margin-bottom: 0;
      }
      input {
        width: initial;
        margin-right: 8px;
      }
    }
  }
}
