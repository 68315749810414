@import "styles/variables.scss";
@import "styles/breakpoints.scss";

.section {
  margin-top: 80px;
  padding-bottom: 60px;
  position: relative;
  // .bg {
  //   background-color: #F4F4F4;
  //   position: absolute;
  //   inset: 0;
  //   left: -100%;
  //   right: -100%;
  //   z-index: -1;
  //   margin: auto;
  //   width: 100vw;
  //   @include breakpoint-up(lg) {
  //     width: 200vw;
  //   }
  // }
  .row {
    margin-top: 48px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    @include breakpoint-up(md) {
      flex-direction: row;
    }
    @include breakpoint-up(lg) {
      justify-content: space-around;
    }
    .col {
      margin-bottom: 16px;
      @include breakpoint-up(md) {
        margin-bottom: 0;
      }
      .equivalent {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .iconWrapper {
          background-color: white;
          border-radius: 32px;
          padding: 28px;
          width: 170px;
          height: 170px;
          display: flex;
          align-items: center;
          justify-self: center;

          path {
            fill: $primary-color !important;
          }
        }
        .textWrapper {
          text-align: center;
          margin-top: 16px;
          @include breakpoint-up(md) {
            margin-top: 33px;
          }
          .value {
            font-size: 28px;
          }
          .label {
            font-size: 20px;
          }
        }
      }
    }
  }
}
